<template>
    <div class="page">
        
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        created() {
            
        },
        methods: {

            
        }
    }
</script>
<style lang="scss" scoped>

    .page{
        background-color: #fff;
        padding:0 20px 20px 20px;
        border-radius: 10px;
        height: calc(100% - 20px);
        overflow: auto;
    }

</style>